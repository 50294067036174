<template>
  <div class="container-fluid p-0" v-if="product !== {}">

  <!-- <div v-for="product in products" :key="product.id" > -->
      <!-- <div v-if="product.id == $route.params.id" > -->
        <div class="row product-container mx-auto">

          <!-- Product Image -->
          <div class="col-12 col-lg-6 p-0">
            <img v-if="product.image" :src="product.image.url" alt="product image" class="img-fluid sticky-image" />
          </div>
          <!-- Product Info -->
          <div class="col-12 col-lg-6 mx-auto d-flex flex-column align-items-center py-5">
            <div class="product-info mx-auto d-flex flex-column align-items-center">
              <!-- <h2 class="text-green text-center montserrat-title pt-xl-5">Get Blaked {{ product.item_data.name }} Cookie - <span class="strikeout-red"><span class="text-green">${{formatPrice(amount)}}</span></span> ${{formatPrice(saleAmount)}}</h2> -->
              <h2 class="text-green text-center montserrat-title pt-xl-5">Get Blaked {{ product.item_data.name }} Cookie - <span class="text-green">${{formatPrice(amount)}}</span></h2>
              <div class="size-container mt-3 mb-4" style="width: 100%;">
                <p class="text-green montserrat-regular text-center text-uppercase d-none d-lg-block">Size</p>
                <div class="d-flex justify-content-between px-lg-5 my-3 mt-lg-4">
                  <div v-for="variation in product.item_data.variations" :key="variation.id">
                    <!-- <span class="variation py-3 px-3 montserrat-regular" style="white-space: nowrap;" :class="{ 'active': variation == selectedVariation }" @click="selectedVariation = variation"> -->
                    <span class="variation py-3 px-3 montserrat-regular" style="white-space: nowrap;" :class="{ 'active': variation == selectedVariation }" @click="updateVariation(variation)">
                      {{ variation.item_variation_data.name }}
                    </span>
                  </div>
                </div>
                
              </div>
              <div class="quantity-container mt-3 mb-5 d-none d-lg-block mx-auto">
                <p class="text-green montserrat-regular text-uppercase text-center d-none d-lg-block">Quantity</p>
                <span class="py-3">
                  <button @click="decrease" class="quantity-button">-</button>
                  <input :placeholder="quantity" v-model="quantity" class="text-center text-green montserrat-regular"/>
                  <button @click="increase" class="quantity-button">+</button>
                </span>
              </div>
              <div class="addtocart-container d-flex justify-content-center">
                <a @click="addToCart(selectedVariation.id, product, quantity, selectedVariation.item_variation_data.price_money.amount, product.image.url, discount)" class="blaked-btn py-2">Add to cart</a>
              </div>
            </div>
            <!-- Product Description, Shipping, Ingredients --> 
            <div class="description-section pt-5 mt-lg-5">
              <div class="d-flex">
                <h4 class="text-green montserrat-large" style="white-space: nowrap;">Description</h4>
                <hr class="horizontal-line">
              </div>
              <p class="text-green montserrat-paragraph">{{ product.item_data.description }}</p>
              <div class="d-flex">
                <h4 class="text-green montserrat-large" style="white-space: nowrap;">Shipping Information</h4>
                <hr class="horizontal-line">
              </div>
              <p class="text-green montserrat-paragraph">Your order will ship the Monday after your order is placed. You must place your order by 8 A.M. on Friday for tyour order to ship the coming Monday.</p>
              <div class="d-flex">
                <h4 class="text-green montserrat-large" style="white-space: nowrap;">Ingredients</h4>
                <hr class="horizontal-line">
              </div>
              <p class="text-green montserrat-paragraph">{{ product.custom_attribute_values["Square:87236596-b79c-4546-8ce1-3e451c038583"].string_value }}</p>
              <div class="d-flex">
                <h4 class="text-green montserrat-large" style="white-space: nowrap;">Disclaimer and Data</h4>
                <hr class="horizontal-line">
              </div>
              <p class="text-green montserrat-paragraph">You can download our labsheets <a :href="pdf" target="_blank" class="download-btn">here.</a> <br>Please also note: <br>The statements made regarding these products have not been evaluated by the Food and Drug Administration. The efficacy of these products has not been confirmed by FDA-approved research. These products are not intended to diagnose, treat, cure or prevent any disease. All information presented here is not meant as a substitute for or alternative to information from health care practitioners. Please consult your health care professional about potential interactions or other possible complications before using any product.</p>
            </div>
          </div>
        </div>
      <!-- </div> -->
    <!-- </div> -->

    <!-- Review Section -->
    <ReviewSection :reviews="reviews"  />

    <!-- FAQ Section -->
    <FAQDrawers :faqs="faqs"  />
  </div>
</template>

<script>
import ReviewSection from '../components/ReviewSection.vue'
import FAQDrawers from '../components/FAQDrawers.vue'

import reviews from '../data/reviews'
import faqs from '../data/faq'
// import products from '../data/products'
// import test from '../assets/get-blaked-labsheets.pdf'

export default {
  name: 'ProductPage',

  props: ['id', 'addToCart', 'catalogList'],

  components: {
    ReviewSection,
    FAQDrawers
  },

  data() {
    return {
      reviews,
      faqs,
      selectedVariation: {},
      quantity: 1,
      amount: '',
      pdf: '/get-blaked-labsheets.pdf',
      discount: '',
      updatedPrice: ''
    }
  },

  computed: {

    sortProduct(index) {
      return {
        product: this.catalogList.item_data
      }
    },

    product() {
      return this.$store.getters['catalog/getProduct'](this.$route.params.id);
    },

    amount() {
      if (this.selectedVariation.item_variation_data.price_money.amount) {
        return this.selectedVariation.item_variation_data.price_money.amount * this.quantity
      } else {
        return null
      }
    },

    // saleAmount() {
    //   if (this.selectedVariation.item_variation_data.price_money.amount) {

    //     let num = this.selectedVariation.item_variation_data.price_money.amount * this.quantity
    //     let percent

    //     this.catalogList.forEach(item => {
    //       if(item.type == 'DISCOUNT' && item.discount_data.name == 'Black Friday Sale') {
    //         this.discount = item.discount_data.percentage

    //         console.log('this.discount', this.discount)

    //         percent = (this.discount / 100) * num
            
    //         return percent
    //       }
    //     })

    //     this.updatedPrice = percent

    //     console.log('this.updatedPrice', this.updatedPrice)

    //     return percent
    //     // return this.selectedVariation.item_variation_data.price_money.amount * this.quantity
    //   } else {
    //     return null
    //   }
    // }

  },

  methods: {

    updateVariation(variation) {
      // console.log('item.item_variation_data.item_id', variation)
      this.selectedVariation = variation
      // this.amount()
    },

     formatPrice(num) {
      const money = num / 100
      return money.toFixed(2)
    },

    // Quantity Button 
    decrease() {
      this.quantity--
      // console.log('decrease')
      // console.log(this.selectedVariation)
      this.updatePrice()
    },

    increase() {
      this.quantity++
      // console.log('increase')
      this.updatePrice()
    },

    // Price 
    updatePrice() {
      if (this.selectedVariation.item_variation_data) {
        this.amount = this.selectedVariation.item_variation_data.price * this.quantity
      }
    }
  },
     // this.amount = this.selectedVariation.item_variation_data.price * this.quantity
  //  },

  //   setDiscount(num) {
  //     this.catalogList.forEach(item => {
  //       if(item.type == 'DISCOUNT' && item.discount_data.name == 'Black Friday Sale') {
  //         this.discount = item.discount_data.percentage

  //   this.selectedVariation = this.product.item_data.variations[0]
  //   console.log('this.selectedVariation', this.selectedVariation)
  //         let percent = (this.discount / 100) * num
          
  //         console.log('PERCENT', percent)
  //         console.log('this.discount', this.discount)

  //         return percent
  //       }
  //     })
  //   }
  // },

  created() {
    this.selectedVariation = this.product.item_data.variations[0]
  }
}
</script>

<style lang="scss" scoped>

  .download-btn {
    color: #11484f !important;
  }
  
  #review-header {
    display: none !important;
  }

  .sticky-image {
    position: sticky;
    top: 0;
    z-index: -1;
  }

  .product-container {
    max-width: 1400px;

    .product-info {
      max-width: 300px;

      @media(min-width: 992px) {
        max-width: 500px;
      }
    }

    // Sale
    .strikeout-red {
      color: #800000;
      text-decoration: line-through;
    }

    // .description-section {
      
    //   @media(min-width: 992px) {
    //     margin-top: -60px;
    //   }

    //   @media(min-width: 1200px) {
    //     margin-top: -120px;
    //   }
    // }

    .horizontal-line {
      opacity: 1;
      height: 1px;
      background-color: #11484f;
      width: 100%;
      margin-left: 20px;
    }

    .variation {
      padding: 5px;
      background-color:#ffffff;
      border: 1px solid #11484f;
      color: #11484f;

      &:hover {
        cursor: pointer;
      }

      &.active {
        background-color: #11484f;
        color: #fff;
        font-weight: 700 !important;
      }
    }

    .quantity-container {
      
      .quantity-button {
        border: none;
        background-color: unset;
        color: #11484f;
        font-family: "Montserrat";
        font-size: 24px;
        font-weight: 400;
        letter-spacing: 0.26px;
        text-align: center;
      }

      input {
        border: none;
        width: 150px;
      }

      span {
        border: 1px solid #11484f;
        border-radius: 2px;
      }
    }
  }

</style>
